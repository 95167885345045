import type { H3Event } from 'h3'

import { invariant } from '@backmarket/utils/function/invariant'

import type { BaseApiSpecs, HttpFetch } from './createHttpFetch'

/**
 * Retrieve the `$httpFetch` instance attached to a given H3 event.
 *
 * Note that the signature of this function follows the convention from `h3`
 * where request helpers are named `getRequestXYZ`, and expect an `event`.
 */
export function getRequestHttpFetch<Specs extends BaseApiSpecs = BaseApiSpecs>(
  event: H3Event,
): HttpFetch<Specs> {
  invariant(event.context.$httpFetch, 'event.context.$httpFetch is missing')

  return event.context.$httpFetch
}

import type { ContentLink } from '@backmarket/http-api/src/api-specs-content/models/content-link'

const EXCEPTION_PATHS: Array<string> = [
  '/bo-seller/',
  '/bo_merchant/',
  '/impact/',
  '/quality',
]
const envRegExp = /local|preprod|dse-.*\.dse|www/
const onelinkRegExp = /backmarket.onelink.me/

export function transformHref({
  href,
  currentUrl,
}: {
  href: string
  currentUrl: URL
}): ContentLink {
  try {
    const url = new URL(href)

    const isUrlEnvSubdomain = url.hostname.match(envRegExp)
    const currentHostname = currentUrl.hostname.replace(envRegExp, '')
    const isExternalPath = EXCEPTION_PATHS.some((path) =>
      url.pathname.match(path),
    )

    if (
      url.hostname.match(currentHostname) &&
      isUrlEnvSubdomain &&
      !isExternalPath
    ) {
      return { href: url.pathname + url.search + url.hash }
    }

    if (url.hostname.match(onelinkRegExp)) {
      return { href }
    }

    return { href, target: '_blank', rel: 'nofollow' }
  } catch (error) {
    return { href }
  }
}

<template>
  <div
    class="bg-surface-default-mid fixed inset-0 top-60 z-10 grid items-center md:absolute"
  >
    <RevLoadingScreen
      data-qa="loading-screen"
      :text="i18n(translations.loading)"
    />
  </div>
</template>

<script setup>
import { navigateTo, preloadComponents, useRoute } from '#imports'
import { onMounted } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'

import { usePaymentNoSsrModuleOptions } from '../../composables/usePaymentNoSsrModuleOptions'

import translations from './PaymentNoSsrLandingPage.translations'

const i18n = useI18n()

const { landingPageRoutePath } = usePaymentNoSsrModuleOptions()
const { path, query } = useRoute()
const destinationPage = path.replace(landingPageRoutePath, '')

preloadComponents(destinationPage)

onMounted(async () => {
  await navigateTo({ path: destinationPage, query }, { replace: true })
})
</script>
